import React from "react";
import Headshot from "../../images/headshot.jpg";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import BlogPostPreviewSection from "../../components/BlogPostPreviewSection";

const About = () => {
  return (
    <div className="font-src font-normal">
      <section className="flex lg:pb-40 md:pt-28 md:pb-28 pb-12 lg:flex-row flex-col-reverse">
        <div className="md:pr-40 px-4 md:text-left text-center">
          <RoughNotationGroup show={true}>
            <h1 className="text-5xl py-4 md:pt-0 pt-12">
              Hi there, I&apos;m{" "}
              <RoughNotation type="underline" order={1}>
                Ryan.
              </RoughNotation>
            </h1>

            <div className="text-slate-600">
              <p className="py-4 text-lg leading-relaxed">
                Prior to software I was an active member of the craft beer
                industry . While my career goals have shifted, that experience
                helped me identify the importance of community and continued
                education. Ultimately it was these realizations that led me to a
                career in software development.
              </p>
              <p className="py-4 text-lg leading-relaxed">
                My introduction to software development took place with a local
                program called Epicodus. Our time was spent pair programming for
                eight-hour days over a six month period. During The first third
                of our program, we built a solid understanding of{" "}
                <RoughNotation type="highlight" order={2} color="#fff2c8">
                  Javascript, HTML, and CSS.
                </RoughNotation>{" "}
                Next, we were introduced to strongly-typed languages by spending
                six weeks with{" "}
                <RoughNotation type="highlight" order={2} color="#eadbf6">
                  C# & ASP.NET Core.
                </RoughNotation>{" "}
                Finally, we built upon the fundamentals we learned in JavaScript
                by taking a deep dive into{" "}
                <RoughNotation type="highlight" order={2} color="#d1f6f4">
                  React
                </RoughNotation>{" "}
                and functional programming.
              </p>
              <p className="py-4 text-lg leading-relaxed">
                Currently I am working as a{" "}
                <RoughNotation type="highlight" order={2} color="#c5f2cd">
                  Frontend Developer
                </RoughNotation>{" "}
                for the Portland Public School district. I am also working
                towards completing my{" "}
                <RoughNotation type="highlight" order={2} color="#f9caca">
                  M.S. in Computer Science
                </RoughNotation>{" "}
                from Portland State University. If you have a job opportunity or
                idea you would like to discuss, feel free to{"  "}
                <a href="mailto: hello@ryanspencer.dev">reach out!</a>
              </p>
            </div>
          </RoughNotationGroup>
        </div>
        <img
          src={Headshot}
          alt="Ryan Spencer"
          className="md:w-1/3 md:h-1/3 w-4/6 h-4/6 rounded-full shadow-2xl shadow-gray-400 place-self-center"
        />
      </section>
      <BlogPostPreviewSection />
    </div>
  );
};

export default About;
