import React from "react";

const OutlinedButton = ({ link, buttonText }) => {
  const handleClick = () => {
    window.location.replace(link);
  };

  return (
    <button
      className="py-2 px-8 border-2 border-slate-700 border-solid rounded text-slate-700 shadow-2xl hover:-translate-y-2 duration-300 ease-in-out"
      onClick={handleClick}
      disabled={!link}
    >
      <p>{buttonText}</p>
    </button>
  );
};

export default OutlinedButton;
