import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import BlogPostPreview from "../../components/BlogPostPreview";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  const blogPosts = () => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ryan.spencer1220"
    )
      .then((res) => res.json())
      .then((data) => setPosts(data.items));
  };

  const formatDescription = (description) => {
    const regex = /<[^>]*>?/gm;
    const result = description.replace(regex, "");
    const maxLength = 200;
    return result.slice(0, maxLength) + "...";
  };

  const getPostImage = (description) => {
    const regex = /<img[^>]+src="([^">]+)"/;
    const result = regex.exec(description);
    return result[1];
  };

  useEffect(() => {
    blogPosts();
  });

  return (
    <section className="lg:pb-40 md:pb-28 pb-20">
      <div>
        <h2 className="text-3xl">Posts</h2>
        <p className="text-lg text-slate-700 pt-4 md:pb-20 pb-10">
          Thoughts on what I&apos;m building and learning.
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-10">
        {posts.map((post) => (
          <BlogPostPreview
            img={getPostImage(post.description)}
            alt="Laptop"
            title={parse(post.title)}
            summary={parse(formatDescription(post.description))}
            link={post.link}
          />
        ))}
      </div>
    </section>
  );
};

export default Blog;
