import React from "react";
import OutlinedButton from "./OutlinedButton";
import SolidButton from "./SolidButton";

const ProjectPreview = ({ img, alt, title, summary, liveSite, sourceCode }) => {
  return (
    <div className="grid">
      <img
        src={img}
        alt={alt}
        className="rounded-3xl shadow-md shadow-gray-400 hover:-translate-y-2 duration-300 ease-in-out"
        width={400}
        height={400}
      />
      <div className="py-10">
        <h5 className="text-2xl font-bold">{title}</h5>
        <p className="text-slate-600 py-6">{summary}</p>
        <div className="flex space-x-2 md:space-x-8">
          <OutlinedButton buttonText="View Live Site" link={liveSite} />
          <SolidButton buttonText="View Source Code" link={sourceCode} />
        </div>
      </div>
    </div>
  );
};

export default ProjectPreview;
