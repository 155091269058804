import React from "react";

const SolidButton = ({ buttonText, link }) => {
  const handleClick = () => {
    window.location.replace(link);
  };

  return (
    <button
      className="py-2 px-8 bg-black rounded text-white shadow-2xl hover:-translate-y-2 duration-300 ease-in-out"
      disabled={!link}
      onClick={handleClick}
    >
      <p>{buttonText}</p>
    </button>
  );
};

export default SolidButton;
