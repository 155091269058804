import ProjectPreview from "../../components/ProjectPreview";
import TipOut from "../../images/tipout.png";
import WeatherExtension from "../../images/weather-extension.png";
import AdBlockerExtension from "../../images/adblocker.png";
import FocusTime from "../../images/focus-time.png";
import Sorting from "../../images/sorting.png";
import LearnToReact from "../../images/learn-to-react.png";

const Projects = () => {
  return (
    <section className="lg:pb-40 md:pb-28 pb-20">
      <div>
        <h2 className="text-3xl">Projects</h2>
        <p className="text-lg text-slate-700 pt-4 md:pb-20 pb-10">
          Some of the side projects I&apos;ve been working on:
        </p>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-18 gap-10">
        <ProjectPreview
          img={Sorting}
          alt="sorting algorithm visualizer"
          title="Sorting Algorithm Visualizer"
          summary="Epicodus team week project built with JavaScript. This application allows users to visualize how different sorting algorithms work."
          liveSite="https://visualizer-algorithm-axfo.vercel.app/"
          sourceCode="https://github.com/ryan-spencer1220/sorting-algorithm-visualizer"
        />
        <ProjectPreview
          img={AdBlockerExtension}
          alt="adblocker extension project"
          title="AdBlocker Chrome Extension"
          summary="Google Chrome Extension designed to block ads on websites. This project was created with React/TypeScript and MaterialUI."
          liveSite=""
          sourceCode="https://github.com/ryan-spencer1220/adblocker-extension"
        />
        <ProjectPreview
          img={TipOut}
          alt="tip out project"
          title="Tip Out Mobile Application"
          summary="Mobile application designed to help service industry workers track their income from wages & tips. This application was built with React Native & Supabase."
          liveSite=""
          sourceCode="https://github.com/ryan-spencer1220/tip-out"
        />
        <ProjectPreview
          img={LearnToReact}
          alt="learn to React project"
          title="Learn To React"
          summary="Educational application designed to help users learn the basics of React. This application was built with C# & ASP.NET Core."
          liveSite=""
          sourceCode=""
        />
        <ProjectPreview
          img={FocusTime}
          alt="focus time project"
          title="Focus Time"
          summary="Mobile application created with React Native & Expo. This application allows users to set a timer for a specific task and track their progress."
          liveSite=""
          sourceCode=""
        />
        <ProjectPreview
          img={WeatherExtension}
          alt="weather forecast chrome extension"
          title="Weather Chrome Extension"
          summary="Google Chrome Extension designed to provide users with an updated forecast based on their specific location. This project was created with React/JavaScript and MaterialUI."
          liveSite="https://chromewebstore.google.com/detail/weather-extension/emfimalkmoceegbdfgkhbfohjafkecff?pli=1"
          sourceCode="https://github.com/ryan-spencer1220/weather-chrome-extension"
        />
      </div>
    </section>
  );
};

export default Projects;
