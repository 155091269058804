import React from "react";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import OutlinedButton from "../../components/OutlinedButton";
import ProjectPreview from "../../components/ProjectPreview";
import SolidButton from "../../components/SolidButton";
import Headshot from "../../images/headshot.jpg";
import BlogPostPreviewSection from "../../components/BlogPostPreviewSection";
import AdBlockerExtension from "../../images/adblocker.png";
import Sorting from "../../images/sorting.png";

function Home() {
  return (
    <>
      <div className="md:text-left text-center md:px-0 px-10 font-src font-medium">
        <section className="flex lg:pb-40 md:pt-28 pb-20 lg:flex-row flex-col-reverse">
          <div className="md:pr-40 md:pt-0 pt-10">
            <RoughNotationGroup show={true}>
              <h1 className="md:text-6xl text-3xl">
                Hello! I&apos;m{" "}
                <RoughNotation
                  type="highlight"
                  show={true}
                  color="#d1f6f4"
                  order={1}
                >
                  Ryan,
                </RoughNotation>{" "}
                a developer based in Portland, OR.
              </h1>
              <div className="text-slate-600">
                <p className="py-4 text-lg leading-relaxed">
                  I am a Software Developer & Computer Science student at
                  Portland State University. I have worked with a variety of
                  technologies but primarily with{" "}
                  <RoughNotation type="highlight" order={2} color="#d1f6f4">
                    Python,
                  </RoughNotation>{" "}
                  <RoughNotation type="highlight" order={3} color="#fff2c8">
                    JavaScript,
                  </RoughNotation>{" "}
                  and{" "}
                  <RoughNotation type="highlight" order={4} color="#d1f6f4">
                    C++.
                  </RoughNotation>{" "}
                  I am currently seeking a full-time position as a Software
                  Developer where I can continue to grow and make meaningful
                  contributions to a devlopment team.
                </p>
                <p className="py-4 text-lg leading-relaxed">
                  <RoughNotation type="underline" order={5}>
                    Take a look at my work or get in touch!
                  </RoughNotation>
                </p>
                <div className="grid grid-cols-2 gap-4 py-10">
                  <SolidButton
                    buttonText={"View LinkedIn"}
                    link={"https://www.linkedin.com/in/ryan-spencer-pdx/"}
                  />
                  <OutlinedButton
                    buttonText={"View Github"}
                    link={"https://github.com/ryan-spencer1220"}
                  />
                </div>
              </div>
            </RoughNotationGroup>
          </div>
          <img
            src={Headshot}
            alt="Ryan Spencer"
            className="md:w-1/3 md:h-1/3 w-4/6 h-4/6 rounded-3xl shadow-2xl shadow-gray-400 place-self-center hover:-translate-y-4 duration-300 ease-in-out"
          />
        </section>
        <section className="lg:pb-40 md:pb-28 pb-20">
          <div>
            <h2 className="text-3xl">Projects</h2>
            <p className="text-lg text-slate-700 pt-4 md:pb-20 pb-10">
              Some of the side projects I&apos;ve been working on:
            </p>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-20 gap-10">
            <ProjectPreview
              img={Sorting}
              alt="sorting algorithm visualizer"
              title="Sorting Algorithm Visualizer"
              summary="Epicodus team week project built with JavaScript. This application allows users to visualize how different sorting algorithms work."
              liveSite="https://visualizer-algorithm-axfo.vercel.app/"
              sourceCode="https://github.com/ryan-spencer1220/sorting-algorithm-visualizer"
            />
            <ProjectPreview
              img={AdBlockerExtension}
              alt="adblocker extension project"
              title="AdBlocker Chrome Extension"
              summary="Google Chrome Extension designed to block ads on websites. This project was created with React/TypeScript and MaterialUI."
              liveSite="https://github.com/ryan-spencer1220/adblocker-extension"
              sourceCode="https://github.com/ryan-spencer1220/adblocker-extension"
            />
          </div>
        </section>
        <BlogPostPreviewSection />
        <section>
          <h2 className="text-3xl">
            <RoughNotation type="underline" show={true} color="black">
              Get In Touch
            </RoughNotation>
          </h2>
          <p className="text-lg text-slate-700 pt-4 pb-20">
            Do you have a job opportunity or idea you would like to discuss?
            Feel free to reach me at{" "}
            <a
              style={{
                fontWeight: "bold",
                backgroundClip: "text",
                backgroundImage: "linear-gradient(to right, #1d4ed8, #64748b)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              href="mailto:ryan.spencer1220@gmail.com"
            >
              ryan.spencer1220@gmail.com
            </a>
            . You can also find me on{" "}
            <a
              style={{
                fontWeight: "bold",
                backgroundClip: "text",
                backgroundImage: "linear-gradient(to right, #1d4ed8, #64748b)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              href="https://github.com/ryan-spencer1220"
            >
              Github
            </a>{" "}
            and{" "}
            <a
              style={{
                fontWeight: "bold",
                backgroundClip: "text",
                backgroundImage: "linear-gradient(to right, #1d4ed8, #64748b)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              href="https://www.linkedin.com/in/ryan-spencer-pdx/"
            >
              LinkedIn
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
}

export default Home;
