import { RoughNotation } from "react-rough-notation";

const BlogPostPreview = ({ link, img, alt, title, summary }) => {
  return (
    <div className="font-src font-normal">
      <a href={link}>
        <img
          src={img}
          alt={alt}
          className="rounded-3xl shadow-md shadow-gray-400 hover:-translate-y-2 duration-300 ease-in-out w-96 h-64"
        />
      </a>
      <div className="py-10">
        <h5 className="text-2xl font-bold">{title}</h5>
        <p className="text-slate-600 py-6">{summary}</p>
        <RoughNotation type="box" show={true} color="black">
          <a href={link} className="text-slate-600 font-bold">
            Read More
          </a>
        </RoughNotation>
      </div>
    </div>
  );
};

export default BlogPostPreview;
