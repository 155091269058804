import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Blog from "./pages/Blog";

function App() {
  return (
    <BrowserRouter>
      <header className="md:sticky md:top-0 md:z-50 md:container md:mx-auto">
        <Navbar />
      </header>
      <main className="relative md:container md:mx-auto 2xl:px-40 xl:px-24 lg:px-16 py-14">
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="blog" element={<Blog />} />
        </Routes>
        <Footer />
      </main>
    </BrowserRouter>
  );
}

export default App;
