import { RoughNotation } from "react-rough-notation";
import { Link } from "react-router-dom";
import { RxGithubLogo } from "react-icons/rx";
import { RxLinkedinLogo } from "react-icons/rx";

const Navbar = () => {
  return (
    <nav>
      <ul className="md:hidden text-xl text-center py-8 px-10 grid grid-cols-3 font-src font-normal">
        <li className="col-start-1 col-span-1">
          <RoughNotation type="box" show={true} color="black">
            <p>RYAN SPENCER</p>
          </RoughNotation>
        </li>
        <li className="col-start-3 col-span-1 flex justify-end items-centern gap-6">
          <a href="https://github.com/ryan-spencer1220">
            <RxGithubLogo className="text-4xl" />
          </a>
          <a href="https://www.linkedin.com/in/ryan-spencer-pdx/">
            <RxLinkedinLogo className="text-4xl" />
          </a>
        </li>
      </ul>
      <ul className="md:flex hidden flex-row text-xl justify-between content-center bg-white py-8 w-full">
        <li>
          <Link to="/">RYAN SPENCER</Link>
        </li>
        <div className="flex flex-row justify-evenl content-center">
          <li className="px-6">
            <Link to="/about">About</Link>
          </li>
          <li className="px-6">
            <Link to="/projects">Projects</Link>
          </li>
          <li className="ps-6 pe-10">
            <Link to="/blog">Blog</Link>
          </li>
          <RoughNotation type="box" show={true} color="black">
            <a href="mailto: ryan.spencer1220@gmail.com">
              <li className="px-6">Contact</li>
            </a>
          </RoughNotation>
        </div>
      </ul>
    </nav>
  );
};
export default Navbar;
